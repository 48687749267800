import React from 'react';
import { FileText, Shield, AlertTriangle } from 'lucide-react';

const LegalPage = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold mb-6">Legal Information</h1>
      
      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 flex items-center">
          <FileText className="mr-2 text-purple-400" />
          Terms of Use
        </h2>
        <p className="mb-4">By using the Weight Loss Calculator website, you agree to comply with and be bound by the following terms and conditions of use:</p>
        <ul className="list-disc list-inside mb-4">
          <li>The content of this website is for informational purposes only and is not intended to be a substitute for professional medical advice, diagnosis, or treatment.</li>
          <li>Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.</li>
          <li>Never disregard professional medical advice or delay in seeking it because of something you have read on this website.</li>
          <li>We reserve the right to modify or discontinue, temporarily or permanently, the website or any features or portions thereof without prior notice.</li>
          <li>You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website.</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 flex items-center">
          <Shield className="mr-2 text-purple-400" />
          Privacy Policy
        </h2>
        <p className="mb-4">Your privacy is important to us. Our privacy policy explains how we collect, use, protect or otherwise handle your Personally Identifiable Information:</p>
        <ul className="list-disc list-inside mb-4">
          <li>We do not collect any personal information unless you voluntarily provide it to us.</li>
          <li>Any personal information you provide is used solely for the purpose of improving our website and user experience.</li>
          <li>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 flex items-center">
          <AlertTriangle className="mr-2 text-purple-400" />
          Disclaimer
        </h2>
        <p className="mb-4">The information provided by Weight Loss Calculator is for general informational purposes only. All information on the site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the site.</p>
        <p className="mb-4">Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4">Changes to Our Legal Policies</h2>
        <p className="mb-4">We may update our legal policies from time to time. We will notify you of any changes by posting the new policies on this page.</p>
        <p>You are advised to review this page periodically for any changes. Changes to this policy are effective when they are posted on this page.</p>
      </section>

      {/* Ad Placement: In-content */}
      {/* TODO: Remove this comment and the placeholder div when deploying ads */}

    {/*

      <div className="bg-gray-800 p-4 my-8 text-center">
        <p className="text-gray-400">Advertisement</p>
      </div>

    */}


    </div>
  );
};

export default LegalPage;