import React, { useState } from 'react';
import { BookOpen, Scale, Apple, Activity, Brain, Moon, X, Info,Zap, Salad } from 'lucide-react';

const FullPageContent = ({ item, onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 text-white overflow-y-auto z-50 p-4">
      <div className="max-w-4xl mx-auto">
        <button 
          onClick={onClose}
          className="fixed top-4 right-4 text-white hover:text-purple-400 bg-gray-800 rounded-full p-2"
          aria-label="Close"
        >
          <X size={24} />
        </button>
        <h2 className="text-3xl font-bold mb-6 flex items-center pt-12">
          {item.icon}
          <span className="ml-2">{item.title}</span>
        </h2>
        <div className="prose prose-invert max-w-none">
          {item.content}
        </div>
      </div>
    </div>
  );
};

const KnowledgeCard = ({ item, onClick }) => {

  return (
    <div 
      className="bg-gray-800 rounded-lg shadow-lg mb-6 p-4 cursor-pointer hover:bg-gray-700 transition-colors duration-200"
      onClick={onClick}
    >
      <h3 className="text-xl font-semibold flex items-center text-purple-400 mb-2">
        {item.icon}
        <span className="ml-2">{item.title}</span>
      </h3>
      <p>{item.summary}</p>
    </div>
  );
};

const KnowledgeRepositoryPage = () => {
  const [expandedItem, setExpandedItem] = useState(null);

  const knowledgeItems = [
    {
      title: "Rethinking Weight Loss",
      icon: <Scale className="mr-2" size={24} />,
      summary: "Discover why traditional weight loss methods often fail and learn a more effective, sustainable approach to achieving your ideal body composition.",
      content: (
        <>
          <h3 className="text-2xl font-semibold mb-4">Beyond the Scale: A New Perspective on Body Transformation</h3>
          <p className="mb-4">The fitness industry has long been fixated on weight loss, but this narrow focus often leads to frustration and yo-yo dieting. It's time to shift our perspective and focus on overall body composition and health.</p>
          
          <h4 className="text-xl font-semibold mb-2">The Problem with Traditional Weight Loss</h4>
          <p className="mb-4">Traditional weight loss methods often fail because:</p>
          <ul className="list-disc list-inside mb-4">
            <li>They rely on unsustainable calorie restriction</li>
            <li>They don't account for individual metabolic differences</li>
            <li>They often lead to loss of muscle mass, slowing metabolism</li>
            <li>They ignore the importance of body composition</li>
          </ul>
          
          <h4 className="text-xl font-semibold mb-2">Embracing Body Recomposition</h4>
          <p className="mb-4">Instead of focusing solely on weight, consider these key factors:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Body fat percentage</li>
            <li>Muscle mass</li>
            <li>Metabolic health markers (blood pressure, cholesterol, etc.)</li>
            <li>Energy levels and overall well-being</li>
          </ul>
          
          <h4 className="text-xl font-semibold mb-2">The Power of Resistance Training</h4>
          <p className="mb-4">Incorporating resistance training into your fitness routine can:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Increase muscle mass, boosting metabolism</li>
            <li>Improve insulin sensitivity</li>
            <li>Enhance overall body composition</li>
            <li>Provide long-term metabolic benefits</li>
          </ul>
          
          <p>Remember, sustainable fitness is about creating a lifestyle that supports your health goals, not just chasing a number on the scale.</p>
        </>
      )
    },
    {
      title: "Nutrition Reimagined",
      icon: <Apple className="mr-2" size={24} />,
      summary: "Learn how to fuel your body effectively without restrictive dieting, and discover the power of intuitive eating.",
      content: (
        <>
          <h3 className="text-2xl font-semibold mb-4">Nourishment Over Restriction: A Fresh Approach to Eating</h3>
          <p className="mb-4">Restrictive diets often lead to a cycle of deprivation and overindulgence. It's time to reimagine nutrition as a way to nourish and energize your body, rather than a set of rigid rules.</p>
          
          <h4 className="text-xl font-semibold mb-2">The Pitfalls of Diet Culture</h4>
          <p className="mb-4">Common issues with traditional dieting include:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Unsustainable restrictions leading to binge eating</li>
            <li>Nutrient deficiencies from eliminating food groups</li>
            <li>Negative impacts on mental health and body image</li>
            <li>Ignoring individual nutritional needs and preferences</li>
          </ul>
          
          <h4 className="text-xl font-semibold mb-2">Embracing Intuitive Eating</h4>
          <p className="mb-4">Intuitive eating principles to consider:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Honoring your hunger and fullness cues</li>
            <li>Making peace with all foods</li>
            <li>Challenging the food police mentality</li>
            <li>Respecting your body's unique needs</li>
          </ul>
          
          <h4 className="text-xl font-semibold mb-2">Nutrient-Dense Eating</h4>
          <p className="mb-4">Focus on incorporating nutrient-dense foods:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Colorful fruits and vegetables</li>
            <li>Lean proteins for muscle support</li>
            <li>Healthy fats for hormone balance</li>
            <li>Complex carbohydrates for sustained energy</li>
          </ul>
          
          <p>Remember, the goal is to create a positive relationship with food that supports your overall health and well-being, not just short-term weight loss.</p>
        </>
      )
    },
    {
      title: "Mindful Movement",
      icon: <Activity className="mr-2" size={24} />,
      summary: "Explore the benefits of mindful movement and learn how to create an exercise routine you'll actually enjoy.",
      content: (
        <>
          <h3 className="text-2xl font-semibold mb-4">Finding Joy in Movement: Beyond Calorie Burning</h3>
          <p className="mb-4">Exercise shouldn't feel like punishment. By shifting our focus from calorie burning to mindful movement, we can create sustainable fitness habits that enhance both physical and mental well-being.</p>
          
          <h4 className="text-xl font-semibold mb-2">The Problem with Exercise as Punishment</h4>
          <p className="mb-4">Common issues with traditional exercise approaches:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Overemphasis on intense, high-impact workouts</li>
            <li>Ignoring individual preferences and physical limitations</li>
            <li>Focusing solely on calorie burning</li>
            <li>Neglecting the mental health benefits of movement</li>
          </ul>
          
          <h4 className="text-xl font-semibold mb-2">Embracing Mindful Movement</h4>
          <p className="mb-4">Principles of mindful movement:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Listening to your body's needs and limitations</li>
            <li>Focusing on how movement makes you feel, not just how it changes your body</li>
            <li>Incorporating a variety of movement types</li>
            <li>Prioritizing consistency over intensity</li>
          </ul>
          
          <h4 className="text-xl font-semibold mb-2">Exploring Different Forms of Movement</h4>
          <p className="mb-4">Consider trying various forms of exercise:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Yoga or Pilates for flexibility and mind-body connection</li>
            <li>Dance for cardiovascular health and self-expression</li>
            <li>Strength training for muscle health and bone density</li>
            <li>Outdoor activities like hiking or cycling for connection with nature</li>
          </ul>
          
          <p>Remember, the best exercise is the one you enjoy and can stick with long-term. Focus on finding movement that brings you joy and energy.</p>
        </>
      )
    },

    {
        title: "Mindset Mastery",
        icon: <Brain className="mr-2" size={24} />,
        summary: "Develop a growth mindset and learn psychological strategies to overcome obstacles in your fitness journey.",
        content: (
          <>
            <h3 className="text-2xl font-semibold mb-4">Mastering Your Mindset: The Key to Long-Term Success</h3>
            <p className="mb-4">Your mindset plays a crucial role in achieving and maintaining your fitness goals. By developing a growth mindset and employing effective psychological strategies, you can overcome obstacles and create lasting change.</p>
            
            <h4 className="text-xl font-semibold mb-2">Embracing a Growth Mindset</h4>
            <p className="mb-4">Key principles of a growth mindset in fitness:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Viewing challenges as opportunities for growth</li>
              <li>Embracing the process, not just the outcome</li>
              <li>Learning from setbacks rather than being discouraged by them</li>
              <li>Believing in your ability to improve and develop new skills</li>
            </ul>
            
            <h4 className="text-xl font-semibold mb-2">Overcoming Mental Barriers</h4>
            <p className="mb-4">Strategies to push past mental obstacles:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Identifying and challenging negative self-talk</li>
              <li>Setting process-oriented goals rather than just outcome-oriented ones</li>
              <li>Practicing self-compassion and avoiding perfectionism</li>
              <li>Visualizing success and using positive affirmations</li>
            </ul>
            
            <h4 className="text-xl font-semibold mb-2">Building Sustainable Habits</h4>
            <p className="mb-4">Tips for creating lasting behavioral change:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Starting with small, achievable habits and gradually building up</li>
              <li>Linking new habits to existing routines (habit stacking)</li>
              <li>Creating a supportive environment that makes healthy choices easier</li>
              <li>Celebrating small wins along the way</li>
            </ul>
            
            <p>Remember, developing a resilient mindset is a journey. Be patient with yourself and focus on consistent progress rather than perfection.</p>
          </>
        )
      },
      {
        title: "Restorative Sleep",
        icon: <Moon className="mr-2" size={24} />,
        summary: "Explore the critical role of quality sleep in fitness and learn strategies to optimize your sleep for better recovery and performance.",
        content: (
          <>
            <h3 className="text-2xl font-semibold mb-4">The Power of Restorative Sleep: Your Secret Weapon for Fitness</h3>
            <p className="mb-4">Quality sleep is often overlooked in fitness journeys, but it's a crucial component of overall health, recovery, and performance. Understanding and optimizing your sleep can significantly enhance your fitness results.</p>
            
            <h4 className="text-xl font-semibold mb-2">The Impact of Sleep on Fitness</h4>
            <p className="mb-4">How sleep affects your fitness goals:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Enhances muscle recovery and growth</li>
              <li>Regulates hunger hormones, supporting weight management</li>
              <li>Improves athletic performance and reaction times</li>
              <li>Boosts mental clarity and motivation for workouts</li>
            </ul>
            
            <h4 className="text-xl font-semibold mb-2">Optimizing Sleep Quality</h4>
            <p className="mb-4">Strategies to improve your sleep:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Maintaining a consistent sleep schedule, even on weekends</li>
              <li>Creating a relaxing bedtime routine to signal your body it's time to wind down</li>
              <li>Optimizing your sleep environment (cool, dark, and quiet)</li>
              <li>Limiting exposure to blue light from devices before bedtime</li>
            </ul>
            
            <h4 className="text-xl font-semibold mb-2">Balancing Exercise and Sleep</h4>
            <p className="mb-4">Tips for harmonizing your workout routine with good sleep habits:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Avoiding intense exercise close to bedtime</li>
              <li>Using sleep quality as a metric for recovery and adjusting workout intensity accordingly</li>
              <li>Incorporating relaxation techniques like meditation or yoga to improve sleep quality</li>
              <li>Listening to your body and prioritizing rest when needed</li>
            </ul>
            
            <p>Remember, quality sleep is not a luxury, but a necessity for optimal fitness and overall well-being. Prioritize your sleep as much as your workouts and nutrition for best results.</p>
          </>
        )
      },
      {
        title: "Stress Management",
        icon: <Zap className="mr-2" size={24} />,
        summary: "Learn effective techniques to manage stress, which can significantly impact your fitness progress and overall well-being.",
        content: (
          <>
            <h3 className="text-2xl font-semibold mb-4">Mastering Stress: The Missing Link in Your Fitness Journey</h3>
            <p className="mb-4">Chronic stress can derail even the most dedicated fitness efforts. Understanding the impact of stress on your body and implementing effective management techniques can significantly enhance your fitness results and overall well-being.</p>
            
            <h4 className="text-xl font-semibold mb-2">The Stress-Fitness Connection</h4>
            <p className="mb-4">How stress affects your fitness goals:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Elevates cortisol levels, potentially leading to weight gain</li>
              <li>Impairs recovery from workouts</li>
              <li>Decreases motivation and energy for exercise</li>
              <li>Can lead to emotional eating or skipping workouts</li>
            </ul>
            
            <h4 className="text-xl font-semibold mb-2">Effective Stress Management Techniques</h4>
            <p className="mb-4">Strategies to reduce and manage stress:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Practicing mindfulness meditation or deep breathing exercises</li>
              <li>Engaging in regular physical activity, which can act as a stress reliever</li>
              <li>Prioritizing self-care activities that you enjoy</li>
              <li>Setting boundaries and learning to say no to excessive commitments</li>
            </ul>
            
            <h4 className="text-xl font-semibold mb-2">Balancing Stress and Recovery in Your Fitness Routine</h4>
            <p className="mb-4">Tips for harmonizing stress management with your workout plan:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Incorporating low-intensity activities like yoga or tai chi for active recovery</li>
              <li>Using exercise as a tool for stress relief, not additional stress</li>
              <li>Listening to your body and adjusting workout intensity based on stress levels</li>
              <li>Prioritizing rest and recovery as much as active exercise</li>
            </ul>
            
            <p>Remember, managing stress is not just about relaxation—it's about creating a balanced lifestyle that supports your fitness goals and overall health. Make stress management a key component of your fitness journey.</p>
          </>
        )
      }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-6 md:py-8">
      <div className="bg-gray-900 text-white p-4 md:p-8 rounded-lg shadow-lg mb-6 md:mb-8">
        <h1 className="text-2xl md:text-4xl font-bold mb-4 md:mb-6 flex items-center">
          <BookOpen className="mr-3 text-purple-400" size={36} />
          Weight Loss Knowledge Repository
        </h1>
        
        <p className="mb-4">
          Welcome to our comprehensive collection of weight loss information and resources. 
          Here you'll find evidence-based articles, tips, and guides to support your health journey.
        </p>

        <div className="bg-gray-800 p-4 rounded-lg mt-6 flex items-start">
          <Info className="text-purple-400 mr-3 mt-1 flex-shrink-0" size={24} />
          <div>
            <h2 className="text-xl font-semibold mb-2">How to Use This Repository</h2>
            <ul className="list-disc list-inside">
              <li>Click on any card below to read the full article</li>
              <li>To return to this main page, click the X button in the top right corner of the article</li>
              <li>Browse through various topics to find the information you need</li>
            </ul>
          </div>
        </div>
      </div>

      {knowledgeItems.map((item, index) => (
        <KnowledgeCard 
          key={index} 
          item={item} 
          onClick={() => setExpandedItem(item)} 
        />
      ))}

      {expandedItem && (
        <FullPageContent 
          item={expandedItem} 
          onClose={() => setExpandedItem(null)} 
        />
      )}
    

      {/* Ad Placement: In-content */}
      
    </div>
  );
};

export default KnowledgeRepositoryPage;