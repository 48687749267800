import React, { useState } from 'react';
import { Calculator, Weight, Ruler, Activity, Target } from 'lucide-react';

const CalculatorPage = () => {
  const [formData, setFormData] = useState({
    weight: '',
    height: '',
    heightFt: '',
    heightIn: '',
    age: '',
    gender: 'male',
    activityLevel: 'sedentary',
    goal: 'maintain'
  });
  const [units, setUnits] = useState('metric');
  const [result, setResult] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleUnitChange = (e) => {
    setUnits(e.target.value);
  };

  const convertToMetric = (weight, height) => {
    if (units === 'imperial') {
      weight = weight * 0.453592; // lbs to kg
      const feet = parseInt(formData.heightFt) || 0;
      const inches = parseInt(formData.heightIn) || 0;
      height = (feet * 30.48) + (inches * 2.54); // Convert ft and in to cm
    }
    return { weight, height };
  };

  const calculateBMR = () => {
    const { weight: w, height: h } = convertToMetric(parseFloat(formData.weight), parseFloat(formData.height));
    const age = parseFloat(formData.age);

    if (formData.gender === 'male') {
      return 88.362 + (13.397 * w) + (4.799 * h) - (5.677 * age);
    } else {
      return 447.593 + (9.247 * w) + (3.098 * h) - (4.330 * age);
    }
  };

  const calculateTDEE = (bmr) => {
    const activityMultipliers = {
      sedentary: 1.2,
      light: 1.375,
      moderate: 1.55,
      active: 1.725,
      veryActive: 1.9
    };
    return bmr * activityMultipliers[formData.activityLevel];
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const bmr = calculateBMR();
    const tdee = calculateTDEE(bmr);
    let goalCalories;
    switch(formData.goal) {
      case 'lose':
        goalCalories = tdee - 500;
        break;
      case 'gain':
        goalCalories = tdee + 500;
        break;
      default:
        goalCalories = tdee;
    }
    setResult({
      bmr: Math.round(bmr),
      tdee: Math.round(tdee),
      goalCalories: Math.round(goalCalories)
    });
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Weight Loss Calculator</h1>
      <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg shadow-xl mb-8">
        <div className="mb-4">
          <span className="block text-sm font-medium mb-2">Units</span>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="units"
                value="metric"
                checked={units === 'metric'}
                onChange={handleUnitChange}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">Metric (kg/cm)</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="units"
                value="imperial"
                checked={units === 'imperial'}
                onChange={handleUnitChange}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">Imperial (lbs/ft)</span>
            </label>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-2" htmlFor="weight">
              Weight ({units === 'metric' ? 'kg' : 'lbs'})
            </label>
            <div className="relative">
              <Weight className="absolute top-3 left-3 text-gray-400" size={18} />
              <input
                type="number"
                id="weight"
                name="weight"
                value={formData.weight}
                onChange={handleInputChange}
                className="w-full pl-10 pr-3 py-2 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-2" htmlFor="height">
              Height ({units === 'metric' ? 'cm' : 'ft/in'})
            </label>
            {units === 'metric' ? (
              <div className="relative">
                <Ruler className="absolute top-3 left-3 text-gray-400" size={18} />
                <input
                  type="number"
                  id="height"
                  name="height"
                  value={formData.height}
                  onChange={handleInputChange}
                  className="w-full pl-10 pr-3 py-2 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                  required
                />
              </div>
            ) : (
              <div className="flex space-x-2">
                <div className="relative flex-1">
                  <Ruler className="absolute top-3 left-3 text-gray-400" size={18} />
                  <input
                    type="number"
                    id="heightFt"
                    name="heightFt"
                    value={formData.heightFt}
                    onChange={handleInputChange}
                    placeholder="ft"
                    className="w-full pl-10 pr-3 py-2 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                    required
                  />
                </div>
                <div className="relative flex-1">
                  <input
                    type="number"
                    id="heightIn"
                    name="heightIn"
                    value={formData.heightIn}
                    onChange={handleInputChange}
                    placeholder="in"
                    className="w-full px-3 py-2 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                    required
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium mb-2" htmlFor="age">
            Age
          </label>
          <input
            type="number"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleInputChange}
            className="w-full px-3 py-2 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
            required
          />
        </div>
        <div className="mt-4">
          <span className="block text-sm font-medium mb-2">Gender</span>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="gender"
                value="male"
                checked={formData.gender === 'male'}
                onChange={handleInputChange}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">Male</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="gender"
                value="female"
                checked={formData.gender === 'female'}
                onChange={handleInputChange}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">Female</span>
            </label>
          </div>
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium mb-2" htmlFor="activityLevel">
            Activity Level
          </label>
          <div className="relative">
            <Activity className="absolute top-3 left-3 text-gray-400" size={18} />
            <select
              id="activityLevel"
              name="activityLevel"
              value={formData.activityLevel}
              onChange={handleInputChange}
              className="w-full pl-10 pr-3 py-2 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
            >
              <option value="sedentary">Sedentary</option>
              <option value="light">Lightly Active</option>
              <option value="moderate">Moderately Active</option>
              <option value="active">Active</option>
              <option value="veryActive">Very Active</option>
            </select>
          </div>
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium mb-2" htmlFor="goal">
            Goal
          </label>
          <div className="relative">
            <Target className="absolute top-3 left-3 text-gray-400" size={18} />
            <select
              id="goal"
              name="goal"
              value={formData.goal}
              onChange={handleInputChange}
              className="w-full pl-10 pr-3 py-2 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
            >
              <option value="lose">Lose Weight</option>
              <option value="maintain">Maintain Weight</option>
              <option value="gain">Gain Weight</option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          className="mt-6 w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
        >
          Calculate
        </button>
      </form>

      {result && (
        <div className="bg-gray-800 p-6 rounded-lg shadow-xl">
          <h2 className="text-2xl font-bold mb-4">Results</h2>
          <p className="mb-2">Basal Metabolic Rate (BMR): <span className="text-purple-400">{result.bmr} calories/day</span></p>
          <p className="mb-2">Total Daily Energy Expenditure (TDEE): <span className="text-purple-400">{result.tdee} calories/day</span></p>
          <p className="mb-2">Recommended Daily Calories: <span className="text-purple-400">{result.goalCalories} calories/day</span></p>
        </div>
      )}
    </div>
  );
};

export default CalculatorPage;