import React from 'react';
import { Info, Target, Zap, Heart } from 'lucide-react';

const AboutPage = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold mb-6">About Our Weight Loss Calculator</h1>
      
      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 flex items-center">
          <Info className="mr-2 text-purple-400" />
          Our Mission
        </h2>
        <p className="mb-4">At Weight Loss Calculate, our mission is to empower individuals with the knowledge and tools they need to achieve their health and fitness goals. We believe that understanding your body's energy needs is the first step towards sustainable weight management.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4">The Science Behind Our Calculator</h2>
        <p className="mb-4">Our Weight Loss Calculator uses well-established formulas to estimate your daily calorie needs:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Basal Metabolic Rate (BMR): We use the Mifflin-St Jeor equation, which is considered one of the most accurate for estimating BMR.</li>
          <li>Total Daily Energy Expenditure (TDEE): Your BMR is adjusted based on your activity level to determine your total daily calorie burn.</li>
          <li>Weight Loss/Gain Adjustments: We factor in your goals to recommend a safe and effective calorie target.</li>
        </ul>
        <p>While our calculator provides a great starting point, remember that individual needs can vary. We always recommend consulting with a healthcare professional for personalized advice.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 flex items-center">
          <Target className="mr-2 text-purple-400" />
          Setting Realistic Goals
        </h2>
        <p className="mb-4">Successful weight management is about setting realistic, achievable goals. Here are some tips:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Aim for a moderate weight loss of 0.5-1 kg (1-2 lbs) per week</li>
          <li>Focus on developing sustainable, healthy habits rather than quick fixes</li>
          <li>Celebrate non-scale victories, like increased energy or better fitting clothes</li>
          <li>Be patient and consistent - lasting change takes time</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 flex items-center">
          <Zap className="mr-2 text-purple-400" />
          Beyond Calorie Counting
        </h2>
        <p className="mb-4">While calorie awareness is crucial, remember that the quality of your diet matters too. We encourage:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Eating a variety of nutrient-dense whole foods</li>
          <li>Balancing your macronutrients (proteins, carbs, and fats)</li>
          <li>Staying hydrated</li>
          <li>Incorporating regular physical activity</li>
          <li>Getting adequate sleep and managing stress</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 flex items-center">
          <Heart className="mr-2 text-purple-400" />
          Our Commitment to You
        </h2>
        <p className="mb-4">We're dedicated to providing you with accurate, up-to-date information and tools to support your health journey.</p>
        <p>Remember, your health is unique to you. Use our calculator as a guide, but always listen to your body and consult with healthcare professionals for personalized advice.</p>
      </section>

      {/* Ad Placement: In-content */}
      {/* TODO: Remove this comment and the placeholder div when deploying ads */}

    {/*

      <div className="bg-gray-800 p-4 my-8 text-center">
        <p className="text-gray-400">Advertisement</p>
      </div>


    */}

    </div>

  );
};

export default AboutPage;