import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

// Import components for each page
import HomePage from './components/HomePage';
import CalculatorPage from './components/CalculatorPage';
import KnowledgeRepositoryPage from './components/KnowledgeRepo';
import AboutPage from './components/AboutPage';
import LegalPage from './components/LegalPage';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div className="bg-gray-900 text-gray-100 min-h-screen font-sans">
        <header className="bg-gray-800 p-4">
          <div className="max-w-6xl mx-auto flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-purple-400">
              Weight Loss Calculate
            </Link>
            <nav className="hidden md:block">
              <ul className="flex space-x-6">
                <li><Link to="/" className="text-gray-300 hover:text-purple-400">Home</Link></li>
                <li><Link to="/calculator" className="text-gray-300 hover:text-purple-400">Calculator</Link></li>
                <li><Link to="/knowledgerepo" className="text-gray-300 hover:text-purple-400">Knowledge Repository</Link></li>
                <li><Link to="/about" className="text-gray-300 hover:text-purple-400">About</Link></li>
                <li><Link to="/legal" className="text-gray-300 hover:text-purple-400">Legal</Link></li>
              </ul>
            </nav>
            <button className="md:hidden text-gray-300 hover:text-purple-400" onClick={toggleMenu}>
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </header>

        {isMenuOpen && (
          <div className="md:hidden bg-gray-800 p-4">
            <ul className="flex flex-col space-y-2">
              <li><Link to="/" className="text-gray-300 hover:text-purple-400" onClick={toggleMenu}>Home</Link></li>
              <li><Link to="/calculator" className="text-gray-300 hover:text-purple-400" onClick={toggleMenu}>Calculator</Link></li>
              <li><Link to="/knowledgerepo" className="text-gray-300 hover:text-purple-400" onClick={toggleMenu}>Knowledge Repository</Link></li>
              <li><Link to="/about" className="text-gray-300 hover:text-purple-400" onClick={toggleMenu}>About</Link></li>
              <li><Link to="/legal" className="text-gray-300 hover:text-purple-400" onClick={toggleMenu}>Legal</Link></li>
            </ul>
          </div>
        )}

        <main className="max-w-6xl mx-auto p-4">
          {/* Ad Placement: Top Banner 
          <div className="bg-gray-800 p-4 rounded-lg mb-8 text-center">
            <p className="text-gray-400">Advertisement</p>
          </div>
          */}

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/calculator" element={<CalculatorPage />} />
            <Route path="/knowledgerepo" element={<KnowledgeRepositoryPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/legal" element={<LegalPage />} />
          </Routes>

          {/* Ad Placement: Bottom Banner 
          <div className="bg-gray-800 p-4 rounded-lg mt-8 text-center">
            <p className="text-gray-400">Advertisement</p>
          </div>
          */}
        </main>

        <footer className="bg-gray-800 p-4 text-center mt-8">
          <p className="text-gray-400">2024 Weight Loss Calculate. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
};

export default App;