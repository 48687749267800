import React from 'react';
import { Link } from 'react-router-dom';
import { Calculator, Book, Apple, Activity } from 'lucide-react';

const HomePage = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold mb-6">Welcome to Your Weight Loss Journey</h1>
      <p className="text-xl mb-8">Embark on a transformative path to a healthier you with our comprehensive Weight Loss Calculator and resources.</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div className="bg-gray-800 p-6 rounded-lg shadow-xl">
          <h2 className="text-2xl font-bold mb-4 flex items-center">
            <Calculator className="mr-2 text-purple-400" />
            Precision Calorie Tracking
          </h2>
          <p className="mb-4">Our advanced calculator uses scientifically-backed formulas to provide accurate estimates of your calorie needs. Whether your goal is to lose, maintain, or gain weight, we've got you covered.</p>
          <Link to="/calculator" className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition duration-300">Start Calculating</Link>
        </div>
        
        <div className="bg-gray-800 p-6 rounded-lg shadow-xl">
          <h2 className="text-2xl font-bold mb-4 flex items-center">
            <Book className="mr-2 text-purple-400" />
            Educational Resources
          </h2>
    
          <p className="mb-4">Knowledge is power in your weight loss journey. Our Knowledge Repository is here to support and inspire you every step of the way. Discover insights, gain understanding, and find the motivation to achieve your health goals. Start exploring and empower your progress today!</p>

          <Link to="/knowledgerepo" className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition duration-300">Learn More</Link>
        </div>
      </div>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Why Calorie Tracking Matters</h2>
        <p className="mb-4">Understanding your caloric needs is crucial for effective weight management. Here's why:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Creates awareness of your eating habits</li>
          <li>Helps identify areas for improvement in your diet</li>
          <li>Allows for more informed food choices</li>
          <li>Provides a measurable way to track progress</li>
          <li>Empowers you to take control of your health</li>
        </ul>
        <p>Our calculator takes into account your individual factors to provide a personalized calorie target.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Beyond Calorie Counting</h2>
        <p className="mb-4">While calorie tracking is important, remember that a holistic approach to weight loss includes:</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-bold mb-2 flex items-center">
              <Apple className="mr-2 text-purple-400" />
              Balanced Nutrition
            </h3>
            <p>Focus on whole foods, lean proteins, and plenty of fruits and vegetables.</p>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-bold mb-2 flex items-center">
              <Activity className="mr-2 text-purple-400" />
              Regular Exercise
            </h3>
            <p>Incorporate both cardio and strength training for optimal results.</p>
          </div>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Start Your Journey Today</h2>
        <p className="mb-4">Whether you're just beginning or looking to refine your approach, our Weight Loss Calculator is here to guide you. Remember, small, consistent steps lead to significant results over time.</p>
        <Link to="/calculator" className="bg-purple-600 text-white px-6 py-3 rounded-md hover:bg-purple-700 transition duration-300 inline-block">Calculate Your Calories Now</Link>
      </section>
      
      {/* Ad Placement: In-content */}
      {/* TODO: Remove this comment and the placeholder div when deploying ads */}

      {/*

      <div className="bg-gray-800 p-4 my-8 text-center">
        <p className="text-gray-400">Advertisement</p>
      </div>

     */}
     
    </div>
  );
};

export default HomePage;